<template>
  <div>
    <LiefengModal
        title="推送记录"
        :value="controlForm.formFlag"
        :fullscreen="true"
        width="800px"
        height="600px"
        @input="closeModal"
    >
      <template v-slot:contentarea>
        <Form
            :label-width="100"
            ref="form"
        >
          <Row>
            <Col span="8">
              <FormItem label="推送标题:">
                {{ formData.title }}
              </FormItem>
              <FormItem label="推送模板:">
                {{ formData.template.templateName }}
              </FormItem>
              <FormItem label="推送时间:">
                {{ formData.gmtCreate }}
              </FormItem>
              <FormItem label="推送社区:" v-if="formData.orgCode">
                {{ formData.orgCode }}
              </FormItem>
              <FormItem label="推送机构:" v-if="formData.orgCodeSelf">
                {{ formData.orgCodeSelf }}
              </FormItem>
              <FormItem label="任务包数量:">
                <InputNumber readonly v-model="formData.taskCount"/>
              </FormItem>
              <FormItem label="消息数量:">
                <InputNumber readonly v-model="formData.msgCount"/>
              </FormItem>
              <FormItem label="推送类型:">
                <Tag v-if="formData.batchType ==='1'" color="success">普通通道</Tag>
                <Tag v-if="formData.batchType ==='2'" color="error">加急通道</Tag>
              </FormItem>
              <FormItem label="处理状态:">
                <Tag v-if="formData.batchStatus ==='1'" color="warning">待处理</Tag>
                <Tag v-if="formData.batchStatus ==='2'" color="warning">处理中</Tag>
                <Tag v-if="formData.batchStatus ==='3'" color="success">已处理</Tag>
              </FormItem>
              <FormItem label="归档状态:">
                <Tag v-if="formData.hisStatus ==='1'" color="warning">未归档</Tag>
                <Tag v-if="formData.hisStatus ==='2'" color="success">已归档</Tag>
              </FormItem>
              <FormItem label="推送备注:" v-if="formData.batchRemark">
                {{ formData.batchRemark }}
              </FormItem>
            </Col>
            <Col span="6">
              <div id="base" style="width: 300px;height: 250px"></div>

              <div id="detail" style="width: 300px;height: 250px"></div>
            </Col>
            <Col span="5">
              <Grid :col="4">
                <H1>推送指标</H1>
                <div>
                  <span style="color: green">预推送人数: {{ formData.msgCount }}</span>
                    <br/>
                  <span style="font-size: 12px;color: #b2b2b2">预计推送人数，未经任何拦截判定</span>
                  <br/>
                  <span style="color: green">实际推送人数:{{ formData.msgCount }}</span>
                  <br/>
                  <span style="font-size: 12px;color: #b2b2b2">最终从实际推送的结果反馈的推送人数</span>
                  <br/>
                  <span style="color: green">推送成功人数:{{
                      formData.extra && formData.extra.successTotal
                    }}</span>
                  <br/>
                  <span style="font-size: 12px;color: #b2b2b2">最终从实际推送的结果反馈的推送成功人数</span>
                  <br/>
                  <span style="color: green">推送失败人数:{{
                      formData.extra && (formData.msgCount-formData.extra.successTotal)
                    }}</span>
                  <br/>
                  <span style="font-size: 12px;color: #b2b2b2">最终从实际推送的结果反馈的推送失败人数</span>
                  <br/>
                  <span style="color: green">未订阅人数: {{ formData.extra.notSubscribe }}</span>
                    <br/>
                  <span style="font-size: 12px;color: #b2b2b2">经过程序拦截判断处于未订阅状态的用户数</span>
                  <br/>
                 <span style="color: green">触达识别人次:{{
                      formData.logData && formData.logData['1']
                    }}</span>
                    <br/>
                  <span style="font-size: 12px;color: #b2b2b2">表示从推送点击进入的次数</span>
                  <br/>
                  <span style="color: green">触达成功人次:{{
                      formData.logData && formData.logData['2']
                    }}</span>
                    <br/>
                  <span style="font-size: 12px;color: #b2b2b2">表示从推送点击进入读取信息详情成功的次数</span>
                  <br/>
                  <span style="color: green">触达失败人次:{{
                      formData.logData && formData.logData['3']
                    }}</span>
                    <br/>
                  <span style="font-size: 12px;color: #b2b2b2">表示从推送点击进入读取信息详情失败的次数</span>
                </div>
              </Grid>
            </Col>
            <Col span="5" v-if="formData.extra && formData.extra.resList">
              <H1>推送明细</H1>
              <Grid :col="4">
                <span style="color: green">推送开始时间:{{
                    formData.extra.startTime
                  }}</span>
                <br/>
                <span style="font-size: 12px;color: #b2b2b2">表示从推送消息落库的时间</span>
                <br/>
                <span style="color: green">推送结束时间:{{
                    formData.extra.lastTime
                  }}</span>
                <br/>
                <span style="font-size: 12px;color: #b2b2b2">表示最后一条推送消息完成后上报的时间</span>
                <br/>
                <br/>
                <div v-for="item in formData.extra.resList">
                  <span style="color: green">{{item.resName}}:{{ item.resCount }}</span>
                  <br/>
                  <span v-if="item.resDesc" style="font-size: 12px;color: #b2b2b2">{{item.resDesc}}</span>
                </div>
              </Grid>
            </Col>
          </Row>

          <Table :columns="pageForm.columns" :data="pageForm.data"></Table>
        </Form>
      </template>
      <template v-slot:toolsbar>
        <Button type="info" style="margin-right:10px" @click="closeModal(false)">关闭</Button>
      </template>
    </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal"
import echarts from "echarts";

export default {
  components: { LiefengModal},
  data() {
    return {
      controlForm: {
        formFlag: false,
      },
      extraForm: {},
      formData: {
        extra: {},
        template: {}
      },
      pageForm: {
        columns: [
          {
            title: '任务ID',
            key: 'taskId'
          },
          {
            title: '任务状态',
            key: 'taskStatus',
            render: (h, params) => {
              let color = ''
              let label = ''
              let taskStatus = params.row.taskStatus
              if (taskStatus == '1') {
                label = '待处理'
                color = '#EFBD3CFF'
              }
              if (taskStatus == '2') {
                label = '处理中'
                color = '#EFBD3CFF'
              }
              if (taskStatus == '3') {
                label = '已处理'
                color = '#4EED14FF'
              }
              return h('div',{},label)
            },
          },
          {
            title: '消息数量',
            key: 'taskCount'
          },
          {
            title: '推送耗时',
            key: 'times'
          },
          {
            title: '创建时间',
            key: 'gmtCreate'
          }
        ],
        data: []
      }
    }
  },
  created() {
  },
  methods: {
    open(row) {
      this.controlForm.formFlag = true
      this.extraForm = row
      this.formData = {
        extra: {},
        template: {}
      }
      this.getInfo(row.batchId);
    },
    getCharts1() {
      const chartBox = echarts.init(document.getElementById("base"));
      var option = {
        title: {
          text: '推送情况',
          left: 'center'
        },
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          orient: 'vertical',
          left: 'left',
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            data: [
              {value: this.formData.extra.successTotal, name: '成功', itemStyle: {color: 'green'}},
              {value: this.formData.extra.failTotal, name: '失败', itemStyle: {color: 'red'}},
            ],
          }
        ]
      };
      chartBox.setOption(option);
      // 根据页面大小自动响应图表大小
      window.addEventListener("resize", function () {
        chartBox.resize();
      });
    },
    getCharts() {
      const chartBox = echarts.init(document.getElementById("detail"));

      const resList = this.formData.extra.resList.map(r => {
        return {
          'value': r.resCount,
          'name': r.resName
        }
      });

      var option = {
        title: {
          text: '推送明细',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },

            data: resList
          }
        ]
      };
      chartBox.setOption(option);
      // 根据页面大小自动响应图表大小
      window.addEventListener("resize", function () {
        chartBox.resize();
      });
    },
    getInfo(batchId) {
      this.$get('/gateway/api/v2/message/batch/selectInfoById',{
        batchId
      }).then(r =>{
         this.formData = r.data
        this.pageForm.data = r.data.taskList

        this.getCharts()
        this.getCharts1()
      })
    },
    save() {

    },
    closeModal(flag) {
      if (this.controlForm.formFlag && !flag) {
        this.controlForm.formFlag = flag
        this.$emit('closeModal')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.ivu-grid-border .ivu-grid-item {
  width: 120px;
  height: 120px;
}

.ivu-grid-item-main {
  padding: 20px 10px 20px 20px !important;
}

/deep/ .validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}


.url {
  margin-left: 100px;
  position: relative;
  top: -58px;
}

.extend {
  position: relative;
  top: -58px;

  .extend_item {
    position: relative;
    top: -58px;
    left: 100px;
    margin-bottom: 24px;
    width: 450px;
  }
}

.demo-drawer-footer {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
}

</style>